/* You can add global styles to this file, and also import other style files */
@import './styles/index.scss';

* {
    user-select: none;
    -webkit-user-drag: none;

    &::-webkit-scrollbar-track {
        background-color: transparent;
    }

    &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 6px;
        opacity: 0.5;
        background-clip: padding-box;
        border: 1px solid transparent;
    }
}

html,
body,
main {
    height: 100%;
    width: 100vw;
    overflow: hidden;
}

main {
    background-color: var(--color-black);
}

.container {
    max-width: gem(1000);
    margin: auto;

    &.team-container {
        max-width: gem(1340);
    }

    &.blog-container {
        max-width: gem(980);
    }
}

.footer-container {
    max-width: gem(1150);
    margin: auto;

    .text-primary {
        color: var(--color-footer-text);

        &.black {
            color: var(--color-black);
            font-weight: bold;
        }

        &.purple-light {
            color: var(--color-purple-light);
        }
    }
}

.btn-primary {
    background-color: var(--color-purple);
    border-radius: gem(5);
    height: gem(30);
    width: gem(135);
    border: none;
    outline: none;
    cursor: pointer;
}

footer {
    background-color: var(--color-white);
    margin-top: -1px;

    &.separate {
        background-color: var(--color-team-bg);
        padding-bottom: gem(50);

        .footer-container {
            max-width: gem(1330);
            padding-left: gem(171);
        }
    }
}

section {
    &.white {
        background-color: var(--color-white);

        h2 {
            color: var(--color-black);
        }

        .sub-heading {
            color: #9b9b9b;
        }
    }
}

input[type='text'],
input[type='tel'],
input[type='email'],
textarea {
    width: gem(600);
    height: gem(55);
    border: 1px solid var(--color-gray-border);
    border-radius: gem(5);
    margin-left: gem(77);
    padding: gem(10) gem(15);
    outline: none;
}

textarea {
    height: auto;
    resize: none;
}

label {
    .error-text {
        display: none;
        position: absolute;
        right: 0;
        top: gem(60);
        color: var(--color-red) !important;
    }

    &.error {
        .error-text {
            display: block;
        }

        input {
            border-color: var(--color-red);
        }
    }
}

.slider-nav {
    background-color: var(--color-submenu-bg);

    .btn {
        cursor: pointer;
    }

    h2,
    .text-primary {
        color: var(--color-black);
    }

    .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: gem(50);
    }
}

@media screen and (min-width: 1440px) {
    .btn-primary {
        border-radius: 5px;
        height: 30px;
        width: 135px;
    }

    .container {
        max-width: 1000px;

        &.team-container {
            max-width: 1340px;
        }

        &.blog-container {
            max-width: 980px;
        }
    }

    .footer-container {
        max-width: 1150px;
    }

    footer {
        &.separate {
            padding-bottom: 50px;

            .footer-container {
                max-width: 1330px;
                padding-left: 171px;
            }
        }
    }

    .slider-nav {
        .container {
            height: 50px;
        }
    }

    input[type='text'],
    input[type='tel'],
    input[type='email'],
    textarea {
        width: 600px;
        height: 55px;
        border-radius: 5px;
        margin-left: 77px;
        padding: 10px 15px;
    }

    textarea {
        height: auto;
    }

    label {
        .error-text {
            top: 60px;
        }
    }
}

@media screen and (max-width: 768px) {
    .btn-primary {
        width: gem(400);
        height: gem(80);
    }
}

@media screen and (max-width: 500px) {
    .container,
    .footer-container,
    .container.team-container,
    .container.blog-container {
        max-width: var(--mobile-container-width);
    }

    footer.separate {
        padding-bottom: 0;
        .footer-container {
            padding: gem(40) 7vw;
            max-width: 100%;
        }
    }

    input[type='text'],
    input[type='tel'],
    input[type='email'],
    textarea {
        width: 100%;
        height: gem(55);
    }

    textarea {
        height: auto;
    }

    label .error-text {
        top: gem(90);
    }
}
