$gem: --🚲;
$var-gem: #{var(#{$gem})};
$gem-coeff: 100;

@function gem($size) {
    @return #{#{$size / $gem-coeff}rem};
}


// The main idea here is to make 1rem = 1px in design,
// so when resizing browser window, content should be just scaled via rems

// What rems are supposed to be used for:
//  – Font sizes
//  - Sizes of containers/controls/components
//  - Margins/Paddings
// Do not use rems for borders!

@mixin remBase($widthDesign, $heightDesign) {
    html {
        font-size: calc(#{$gem-coeff * 100}vw / #{$widthDesign});
    }

    @if $heightDesign != 0 {
        @media all and (min-aspect-ratio: #{$widthDesign} / #{$heightDesign}) {
            html {
                font-size: calc(#{$gem-coeff * 100}vh / #{$heightDesign});
            }
        }
    }
}

@mixin remBreakpoint($widthDesign, $heightDesign, $query: 'max-width') {
    @media all and (#{$query}: #{$widthDesign}px) {
        @include remBase($widthDesign, $heightDesign);
    }
}

// This defines base breakpoint, that will be used by default.
// The second parameter allows to take into account height of viewport by design.
// Visually, specifying height means fitting a container to a screen.
// '0' specified for height means that this breakpoint doesn't care about screen height in design
@include remBase(1440, 0);

// @include remBreakpoint(768, 0);

// @media all and (min-aspect-ratio: 2/1) and (max-aspect-ratio: 6/2) and (min-width: 2000px) and (min-height: 900px) and (max-height: 1200px) {
//     @include remBase(1440, 810);
// }

@media all and (max-width: 500px) {
    @include remBreakpoint(500, 0);
}


// Usually, base breakpoint is for desktop, so other (smaller) bps are specified via 'max-width' media query