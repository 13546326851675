:root {
    --color-white: #fff;
    --color-black: #000;
    --color-purple: #6623c4;
    --color-purple-light: #a64dff;
    --color-purple-text: #a699cc;
    --color-white-text: #cccccc;
    --color-button-text: #e6e6e6;
    --color-footer-text: #a5a5a6;
    --color-footer-link: #4a4a4a;
    --color-gray-border: #b2b2b3;
    --color-team-gray-text: #9b9b9b;
    --color-team-bg: #f5f5f7;
    --color-blog-info: #808080;
    --color-submenu-bg: #f2f2f2;
    --color-red: red;
    --color-green: #31ca2f;
    --color-disabled-text: #d6d6d6;
    --mobile-container-width: 86vw;
}
