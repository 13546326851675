// font-family: 'Roboto'
// font-family: 'Open Sans';

.nav-link {
  font-size: gem(15);
  color: var(--color-white-text);
  font-family: "Open Sans";
}

.btn-primary {
  font-family: "Open Sans";
  color: var(--color-button-text);
  font-size: gem(15);
  text-align: center;
}

h1 {
  font-family: "Roboto";
  font-weight: 600;
  font-size: gem(100);
  color: var(--color-white);
  line-height: gem(75);
  text-align: center;
}

h2 {
  font-family: "Roboto";
  font-weight: 600;
  font-size: gem(80);
  color: var(--color-white);
  line-height: gem(75);
}

.text-primary {
  font-family: "Open Sans";
  font-size: gem(25);
  color: var(--color-white);
  font-weight: bold;

  &.sm {
    font-size: gem(17);
    line-height: gem(20);
    font-weight: 400;
  }

  &.xsm {
    font-size: gem(13);
    line-height: gem(18);
    font-weight: 600;
  }
}

.sub-heading {
  font-size: gem(46);
  line-height: gem(79);
  font-family: "Open Sans";
  display: flex;
}

.press-agenda {
  font-family: "Open Sans";
  font-size: gem(12);
  color: var(--color-purple-light);
  font-weight: 600;
}

p,
a,
span,
h1,
h2,
h3,
.text-primary {
  position: relative;
  z-index: 1;

  &.black {
    color: var(--color-black) !important;
  }

  &.purple {
    color: var(--color-purple-text) !important;
  }

  &.purple-light {
    color: var(--color-purple-light) !important;
  }

  &.white {
    color: var(--color-white) !important;
  }

  &.green {
    color: var(--color-green) !important;
  }
}

h4 {
  font-size: gem(45);
  font-family: "Open Sans";
  line-height: gem(50);
  text-align: start;
}

.team {
  h1 {
    font-size: gem(49);
    line-height: gem(65);
    font-family: "Open Sans";
    font-weight: bold;
  }

  h2 {
    font-family: "Open Sans";
    font-weight: 600;
    font-size: gem(25);
    line-height: gem(34);
    color: var(--color-black);
  }

  .role {
    font-family: "Open Sans";
    font-weight: bold;
    color: var(--color-purple-light);
    font-size: gem(15);
    line-height: gem(20);
    text-transform: uppercase;
  }

  .description {
    font-family: "Open Sans";
    color: var(--color-black);
    font-size: gem(15);
    line-height: gem(20);
  }
}

@media screen and (min-width: 1440px) {
  .nav-link {
    font-size: 15px;
  }

  .btn-primary {
    font-size: 15px;
  }

  h1 {
    font-size: 100px;
    line-height: 75px;
  }

  h2 {
    font-size: 80px;
    line-height: 75px;
  }

  .blog-card-heading {
    font-size: 21px;
    line-height: 29px;
  }

  .text-primary {
    font-size: 25px;

    &.sm {
      font-size: 17px;
      line-height: 20px;
    }

    &.xsm {
      font-size: 13px;
      line-height: 18px;
    }
  }

  .sub-heading {
    font-size: 46px;
    line-height: 79px;
  }

  .press-agenda {
    font-size: 12px;
  }

  .team {
    h1 {
      font-size: 49px;
      line-height: 65px;
    }

    h2 {
      font-size: 25px;
      line-height: 34px;
    }

    .role {
      font-size: 15px;
      line-height: 20px;
    }

    .description {
      font-size: 15px;
      line-height: 20px;
    }
  }
}

@media screen and (max-width: 768px) {
  .nav-link,
  .btn-primary {
    font-size: gem(36);
  }

  .text-primary {
    &.sm {
      font-size: gem(19);
      line-height: gem(22);
      font-weight: 400;
    }

    &.xsm {
      font-size: gem(15);
      line-height: gem(20);
      font-weight: 600;
    }
  }
}

@media screen and (max-width: 500px) {
  h1 {
    font-size: gem(65);
    line-height: gem(55);
  }

  h2 {
    font-size: gem(50);
    line-height: gem(45);
  }

  .text-primary {
    &.sm {
      font-size: gem(23);
      line-height: gem(25);
      font-weight: 500;
    }

    &.xsm {
      font-size: gem(18);
      line-height: gem(23);
      font-weight: 400;
    }
  }

  .team {
    .description {
      font-size: gem(18);
      line-height: gem(24);
    }
  }

  .blog-card-heading {
    font-size: gem(26);
    line-height: gem(29);
  }

  .press-agenda {
    font-size: gem(15);
  }
}
