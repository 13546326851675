header {
    width: 100vw;
    background-color: var(--color-black);
    padding: gem(9) 0 gem(11);
    z-index: 11;

    .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    a {
        display: flex;
        text-decoration: none;
    }

    .logo-wrap {
        position: relative;
        z-index: 101;
        margin-left: gem(-20);
    }

    .logo {
        width: gem(60);
        height: gem(30);
        cursor: pointer;
    }

    .links {
        display: flex;
    }

    .burger-btn {
        display: none;
        position: relative;
        z-index: 101;
        cursor: pointer;

        &:before,
        &:after {
            content: '';
            background-color: var(--color-white);
            display: block;
            height: 2px;
            width: gem(30);
            transition: transform 0.25s ease;
        }

        &:before {
            margin-bottom: gem(10);
        }

        &:after {
            width: gem(26);
        }

        &.active {
            &:before,
            &:after {
                width: gem(30);
                transform-origin: center;
            }

            &:before {
                transform: rotate(45deg) translateY(gem(9));
            }

            &::after {
                transform: rotate(-45deg) translateY(gem(-9));
            }
        }
    }
}

nav {
    margin: 0 gem(89) 0 gem(155);
    display: flex;
    align-items: center;

    .menu {
        list-style: none;
        display: flex;
    }

    li:not(:last-child) {
        margin-right: gem(80);
    }
}

@media screen and (min-width: 1440px) {
    header {
        padding: 9px 0 11px;

        .logo-wrap {
            margin-left: -20px;
        }

        .logo {
            width: 60px;
            height: 30px;
        }

        .burger-btn {
            &:before,
            &:after {
                width: 30px;
            }

            &:before {
                margin-bottom: 10px;
            }

            &:after {
                width: 26px;
            }

            &.active {
                &:before,
                &:after {
                    width: 30px;
                }

                &:before {
                    transform: rotate(45deg) translateY(9px);
                }

                &::after {
                    transform: rotate(-45deg) translateY(-9px);
                }
            }
        }
    }

    nav {
        margin: 0 89px 0 155px;

        li:not(:last-child) {
            margin-right: 80px;
        }
    }
}

@media screen and (max-width: 768px) {
    header {
        padding: gem(30) 0;
        .container {
            justify-content: space-between;
        }

        .logo {
            width: gem(100);
            height: gem(50);
        }

        .logo-wrap {
            margin-left: 0;
        }

        .burger-btn {
            display: block;
        }
    }

    .links {
        position: fixed;
        z-index: 100;
        height: 100vh;
        width: 100vw;
        top: 0;
        left: 0;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: var(--color-black);
        transform: translateX(100%);
        transition: transform 0.3s ease;

        .menu {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        nav {
            margin: 0;
            li {
                width: fit-content;
                margin-bottom: gem(50);
                margin-right: 0;
            }
        }

        &.active {
            transform: translateX(0);
        }
    }
}

@media screen and (max-width: 500px) {
    header {
        .logo {
            width: auto;
            height: gem(50);
        }

        .burger-btn {
            &.active {
                &:before {
                    transform: rotate(45deg) translateY(gem(8));
                }

                &::after {
                    transform: rotate(-45deg) translateY(gem(-8));
                }
            }
        }
    }
}
